.grid-4 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-5 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-8 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-7 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-9 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-11 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.grid-11 {
  display: grid; 
  grid-template-columns: 18% 28% 28% 30%;
  text-align: center;
}
.flex-center-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
/* .flex-center-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
} */
.flex-start-center{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
.flex-around-center{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-between-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}
.fs14{
  font-size:14px;
}
.fs15{
  font-size:15px;
}
.fs18{
  font-size:18px;
}
.fs20 {
  font-size:20px;
  font-weight:600;
}
.fs28 {
  font-size:28px;
  font-weight:600;
}

.color-E1111D{
  color:rgba(225,17,29,1);
}
.color-333{
  color: #333333
}
.color-115{
  color:#115FE1
}
.color-999{
  color:#999999
}
.fw500 {
  font-weight:500;
}
.fw400 {
  font-weight:400;
}
.paddingLR14 {
  padding: 0px 14px;
}
.paddingT24 {
  padding-top: 24px;
}
.paddingT40 {
  padding-top: 40px;
}
.marginB24 {
  margin-bottom: 24px;
}
.marginB16 {
  margin-bottom: 16px;
}
 