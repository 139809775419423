.list {
  min-height: 100px;
  background: rgba(255, 255, 255, 1);
  /* border-radius: 12px; */

  /* padding-left: 15px;
  padding-right: 15px; */
  overflow: hidden;
}
.am-list-extra {
  color: #333 !important;
}
.list-content {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 25px;
}
.item-view {
  width: 375px;
  box-sizing: border-box;
  height: 56px;
  padding-left: 15px;
  padding-right: 15px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-view-item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.page-view {
  width: 375px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
  text-align: left;
}
.divider {
  height: 12px;
  background: #f5f7fb;
}
.header_text {
  height: 32px;
  background: #f5f7fb;
  padding-top: 9px;
font-size: 12px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
}
li {
  list-style-type: none;
  min-height: 56px;
}
li img {
  vertical-align: middle;
}
.li-list {
  min-height: 56px;
  width: 264px;
  padding-left: 12px;
  line-height: 22px;
  /* border-bottom: solid 0.5px #E5E8F0; */
}
.li-span {
  padding-top: 16px;
  padding-bottom: 16px;
}
.select-view {
  display: flex;
  flex-direction: row;
}
.select-item {
  height: 22px;
  margin-left: 15px;
  padding: 4px 23px;
  background: #f5f7fb;
  border-radius: 14px;
  line-height: 22px;
}
.selected-item {
  height: 22px;
  margin-left: 15px;
  padding: 4px 23px;
  background: #115fe1;
  border-radius: 14px;
  line-height: 22px;
}
.selected-text {
  color: #fff;
}
.next {
  width: 185px;
  height: 48px;
  border-radius: 24px;
}
.next2 {
  width: 345px;
  height: 48px;
  margin-left: 15px;
  border-radius: 24px;
}
.submit_botton {
  width: 100vh;
  height: 44px;
  margin: 25px 18px;
  border-radius: 22px;
}
.nexttext {
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 48px;
}
.am-list-item .am-list-line {
  width: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px !important;
  overflow: hidden;
}

.next-two-text-one {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(17, 95, 225, 1);
  line-height: 25px;
  padding-left: 40px;
}
.bottomButton {
  width: 100vw;
  height: 72px;
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 1);
}

.bottomsalf{
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  height: env(safe-area-inset-bottom);
  background: rgba(255, 255, 255, 1);
}
.bottomTap {
  width: 100vw;
  height: 72px;
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  left: 0;
  z-index: 999999;
  margin-top: 30px;
  border-top: solid 0.5px #e5e8f0;
  background: rgba(255, 255, 255, 1);
}
.doneHeader {
  height: 72px;
  width: 100vw;
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  left: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 999999;
}
.img-WH {
  width: 100%;
  height: 100%;
}
.imgDiv-WH {
  width: 24px;
  height: 24px;
}
.onfocus:focus {
  outline: none;
}

/* .onfocus {
  min-height: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
} */
.placeholder {
  width: 100%;
  bottom: 0;
  position: fixed;
  left: 0;
  z-index: 999999;
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
  background-color: #fff;
}
.margin56 {
  margin-left: 56px;
}

#nprogress .bar {
  background: #115fe1 !important;
}
.textArea {
  width: 315px;
  min-height: 24px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.itemTextArea {
  max-width: 200px;
  font-size: 17px;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 22px;
  text-align: right;
}

TextArea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #d0d6e0;
}

TextArea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #d0d6e0;
}

TextArea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #d0d6e0;
}

TextArea:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #d0d6e0;
}

TextArea::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #d0d6e0;
}
.score-title {
  font-size: 14px;
  font-weight: 400;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.score-img {
  padding-top: 12px;
}
.number1 {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 33px;
}
.number2 {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  line-height: 33px;
}
.number {
  margin-top: 18px;
}
.done {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  font-weight: 400;
}
.bottom {
  top: 152px;
  bottom: 0;
  position: absolute;
}
.bottomBt {
  width: 325px;
  height: 44px;
  border-radius: 24px;
  bottom: 20px;
  background: rgba(17, 95, 225, 0.098);
  position: absolute;
}
.bottomText {
  position: absolute;
  font-size: 17px;
  color: #115fe1;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  margin-left: 134px;
}
