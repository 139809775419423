.floating {
  width: 325px;
  padding: 20px 25px;
  background: #fff;
}
.bottom{
  top: 152px;
  bottom: 0;
  position: absolute;
}
.img-WH {
  width: 100%;
  height: 100%;
}
.image {
  width: 48px;
  height: 48px;
}
.page {
  padding-top: 48px;
  height: 100vh;
  box-sizing: border-box;
}
.title1 {
  padding-top: 12px;
}
.bottomBt{
width: 325px;
height: 44px;
border-radius: 24px;
bottom: 20px;
background: rgba(17,95,225,0.098);
position:absolute;
}
.bottomText{
 position:absolute;
font-size: 17px;
color: #115FE1;
line-height: 24px;
text-align: center;
margin-top: 10px;
margin-left: 134px;
}