.bor {
  width: 205px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #D0D6E0;
  margin-bottom: 12px;
}
.img-WH {
  width: 100%;
  height: 100%;
}
.image {
  width: 48px;
  height: 48px;
}
.page {
  padding-top: 48px;
  margin-bottom: 72px;
  height: 100%;
  box-sizing: border-box;
}
.title1 {
  padding-top: 12px;
}
.title2 {
  padding: 40px 30px 32px 30px;
}
.placeholder1{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
  background-color: #fff;
  /* border: 1px solid red; */
}
